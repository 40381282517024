import { useEffect } from "react";
import TrainingCard from "./component/trainingCard";
import Aos from "aos";
import "aos/dist/aos.css";
import TestimonialHero from "./component/TestimonialHero";
import ContactUs from "../../components/ContactUs";

const MainTestimonials = () => {
  const trainingCardInfo = [
    {
      title: "Olukayode Olusanya",
      track: "Founder, The Oak Holdings",
      body: "JVEC Solutions LTD is a top-notch solutions-driven firm and their unwavering focus on client satisfaction is truly commendable. Their expertise and professionalism is highly recommended. They understand the market trends and are able to provide game-changing technologies and innovations to take any business to the next level. They are highly recommended!",
      img: "/images/testimonial_page/Olukayode Olusanya.jpg",
    },
    {
      title: "Nkem Okocha",
      track: "Founder, Mamamoni",
      body: "JVEC Solutions delivered an outstanding Cyber Awareness training that exceeded our expectations. The training was comprehensive, practical, and tailored to our needs, significantly enhancing our overall security posture. Our team is now more vigilant and equipped to mitigate cyber threats effectively.",
      img: "/images/testimonial_page/Nkem Okocha.jpg",
    },
    {
      title: "Dr John Dada",
      track: "CTO, Curacel",
      body: "JVEC Solutions has been instrumental in transforming our cybersecurity posture. Their cybersecurity awareness programme was properly packaged and well delivered.",
      img: "/images/testimonial_page/John Dada.jpeg",
    },    
    {
      title: "Felix",
      track: "Founder, Pent Agency (USA)",
      body: "JVEC Solutions is the real deal; I had a seamless work with them throughout the building of our website from conceptualization to delivery. The feedback was amazing as they made sure it suited what we wanted, keeping in touch with us all the way. I'll surely recommend them any day anytime.",
      img: "/images/testimonial_page/Felix.jpg",
    },
    {
      title: "Praise (United Kingdom)",
      track: "Data Analytics Track",
      body: "I must confess before I started this journey I was very skeptical because I was a newbie, with no experience whatsoever in data analytics, needing so badly to transition from healthcare but being in another country I was worried if I would be able to fit into the program, but so far I can say that I am very happy I took the step of contacting JVEC when my friend recommended.\n\nSo far, I have been pleasantly impressed that regardless of how affordable the program is, it has been the most flexible, easy, and worthwhile self-investment I have made so far. I have been put as a priority at all times, the classes are very easy to follow and the facilitators have been very supportive; the fact that I can engage the facilitators one-on-one and they are always ready to answer my questions no matter how silly they sound is something I don’t think I would have been able to find anywhere else.\n\nI have started our industry-based project with the support of my facilitator and I am sure after this I will be more than confident to handle the next one myself.",
      img: "/images/testimonial_page/Mrs Praise.jpeg",
    },
    {
      title: "Emmanuel",
      track: "CEO/Founder, Careers Verified (Nigeria)",
      body: "Compared to other companies, JVEC Solutions has been far and above the best in every area. While building our website, the customer service has been prompt and straightforward and the actual services are well documented and logical to our needs. We are very excited working with JVEC Solutions. I will gladly recommend them to anyone.",
      img: "/images/testimonial_page/Emma Nwokeji testimonial.jpg",
    },
    {
      title: "Shadrack (Germany)",
      track: "Project Management Track",
      body: "I recently completed a project management course with JVEC, and the experience was outstanding. The training was comprehensive, well-structured, and provided valuable insights that have significantly enhanced my skills. JVEC’s professionalism and dedication to excellence is truly commendable. I highly recommend their services to anyone looking to advance their project management capabilities.",
      img: "/images/testimonial_page/default.jpeg"
    },
    {
      title: "Olumide (Nigeria)",
      track: "Data Analytics Track",
      body: "Few months ago, I saw an advert on Data Analytics Training posted by Social media influencer - Inneh Joseph on LinkedIn. The training paved way for me in my new job - the first task given to me as Head of HR was in Excel; when I opened the Excel file, I saw the first topic (filter) treated in my first class. I recommend JVEC to all HR and Admin personnel, you need proficiency in Excel to report to management.",
      img: "/images/testimonial_carousel/avatar1.webp",
    },
    {
      title: "Bisola (Nigeria)",
      track: "Software Engineering Track",
      body: "Finding a platform that helps secure an internship after learning is challenging. Enrolling in the backend development program at JVEC not only solved this issue but also offered invaluable one-on-one mentor sessions. The practical classes are suitable for programmers at any level. The project-based training and internship opportunities provide essential real-world experience. Plus, the flexible payment options make it financially accessible.",
      img: "/images/testimonial_page/default.jpeg"
    },
    {
      title: "Enoch (Nigeria)",
      track: "Cybersecurity Track",
      body: "I took an online cybersecurity course at JVEC Solutions and was blown away by the highly practical classes. The hands-on training and real-world examples helped me master critical security skills, making me more effective. The course was engaging and interactive, giving me the confidence to tackle complex security challenges. I highly recommend JVEC Solutions to anyone looking to upgrade their cybersecurity expertise!",
      img: "/images/testimonial_page/Enoch Tidiings.jpeg"
    },
    {
      title: "Deborah (Nigeria)",
      track: "Motion Graphics Track",
      body: "JVEC Solutions is a tech company I would recommend any time, any day to anybody interested in going into tech. They offer the best training services, and one unique thing about them is the fact that you can intern to gain more experience, so they are not just training you and throwing you out to the world, you will be refined, polished, and finessed.\n\nDistance is not a barrier when it comes to learning at JVEC Solutions; there are virtual classes and you can join from any part of the world. Also, the classes are recorded so you can always go back to review what you have learned.\n\nNot having prior knowledge about the tech course is not a limitation because they train you from the preliminaries and you have a mentor who will guide you throughout the training process.\n\nI would advise that you make JVEC Solutions your ride into the tech world because you will be riding on a whole new level with your skills.",
      img: "/images/testimonial_page/Deborah Arogundade.jpeg"
    },
    {
      title: "GLory (Nigeria)",
      track: "Motion Graphics Track",
      body: "I learned a lot about Motion Graphics at JVEC Solutions' boot camp. The hands-on training was excellent, and the instructors were very supportive. The practical experience really boosted my confidence and skills. I would highly recommend JVEC Solutions to anyone looking to get into tech. Their program is well-organized and truly prepares you for the industry.",
      img: "/images/testimonial_page/glory.jpg",
    },
    {
      title: "Tobi (Nigeria)",
      track: "Product Design Track",
      body: "The training was an eye opener for me. I learnt new terminologies in product design. It was project based and theoretical. I engaged in projects and with that I was able to have some experience on designing mobile apps and websites. I learnt the proper way of presenting a design system. I was also taught how to build portfolios. The classes were top notch. No doubt about it. I will like to be an intern in JVEC Solutions Incorporated to gain more industry experience on product design.",
      img: "/images/testimonial_page/Oluwatobi.jpg",
    },
    {
      title: "Blessing (Nigeria)",
      track: "Business Development Mgt. Track",
      body: "I took the path in the free boot camp in December 2023 which I didn't expect to be so wonderful. It was worth more than I expected because I was taught like I paid for it. The tutors are wonderful people who are ready to take you step by step and guide you in areas you are facing challenges with. I signed up for the business development management (BDM) class  which is helping me so well now.\n\n I can help business owners grow their business and also give advice on business management and this is only possible because of Jtech. They are also the best you can trust as a beginner in tech because they're ready to take you step by step, Indeed they're the solution.",
      img: "/images/testimonial_page/Joseph.jpg",
    },
    {
      title: "Emeka (Nigeria)",
      track: "Product Design Track",
      body: "The courses are beginner friendly, the instructors prioritise students understanding. And the Internship programme is a sure way to start a tech journey.",
      img: "/images/testimonial_page/emeka.jpg",
    },
    
    {
      title: "Boluwatife (Nigeria)",
      track: "WordPress Track",
      body: "I recently had the pleasure of attending JVEC Solutions' WordPress design class, and I must say it was an exceptional experience! The class was expertly taught, well-organized, and provided a comprehensive understanding of WordPress design principles and practices. The instructor's enthusiasm and patience made the learning process enjoyable and engaging.\n\nI appreciated the hands-on approach, which allowed me to apply my newfound knowledge immediately. JVEC Solutions' WordPress design class has empowered me to take my web design skills to new heights, and I highly recommend it to anyone seeking to enhance their skills in this area. Thank you, JVEC Solutions, for offering such a valuable resource!",
      img: "/images/testimonial_page/boluwatife.jpg",
    },
    {
      title: "Damilola (Nigeria)",
      track: "WordPress Track",
      body: "I’m glad to be part of the 2023 WordPress Bootcamp. It was insightful and I was new to the tech industry then but fell in love with tech; the class was well-detailed and the facilitator made the class easy to assimilate.",
      img: "/images/testimonial_page/Akinlaja.jpg",
    },
    {
      title: "Abiodun (Nigeria)",
      track: "Data Science Track",
      body: "Although I expected the Boot camp to be a tough row to hoe, JVEC Solutions' comprehensive and well-structured curriculum made it a walk in the park. The program covered a wide range of topics and projects, providing valuable hands-on experience with various tools and technologies. This boosted my confidence in tackling real-world data challenges.\n\nThe tutors were incredibly knowledgeable and supportive, making it feel engaging and interactive. There were plenty of opportunities to ask questions and get feedback. I'm super grateful for the experience and skills I gained from this boot camp.",
      img: "/images/testimonial_page/abiodun.jpg",
    },
    {
      title: "Toluwani (Nigeria)",
      track: "Data Analytics Track",
      body: "I recently attended the free data analytics boot camp, and I was thoroughly impressed! The training provided me with hands-on experience and a solid understanding of data analytics concepts. The trainers were knowledgeable, and supportive, and made complex topics easy to grasp.\n\nI highly recommend JVEC Solutions to anyone looking to learn about tech, as their training is top-notch, and their dedication to empowering individuals is evident. The experience was truly enriching, and I look forward to exploring more opportunities with them in the future!",
      img: "/images/testimonial_page/toluwani.jpg",
    },
    {
      title: "Victoria (Nigeria)",
      track: "Business Development Mgt. Track",
      body: "I recently took the Business Development Management training, and my experience there was worth it. My teacher - Ms. Sophia, was very practical with the subject. She was friendly as well. She gave assignments for application purposes and reviewed the assignments in the next class before delving into the matter of the day. She made the sessions such that every day, we left with value. We discussed case studies.\n\nI will recommend JVEC Solutions because they are particular about their approach, systems, and majorly about their students. Thank you for the all-round wonderful experience!",
      img: "/images/testimonial_page/victoria.jpg",
    },
    {
      title: "Muhammad (Nigeria)",
      track: "WordPress Track",
      body: "I have greatly benefitted from the free boot camp on web development that was conducted by JVEC Solutions; it was an educational and practical way of learning that has made me clearly understand concepts that I had a lot of problems understanding. I have been inspired to further my career as a front-end web developer. Shout out to JVEC Solutions. Thank you very much.",
      img: "/images/testimonial_page/mohammed.jpg",
    },
    {
      title: "Samuel (Nigeria)",
      track: "Frontend Development Track",
      body: "I participated in JVEC Solutions’ Software development boot camp last year December, although I was already learning on my own, JVEC’s training really helped me with things like Media Query (Responsiveness), Flex and Grid, CSS Pseudo Elements, and CSS Positioning. I was able to complete more than two projects and add to my portfolio during that period. The two weeks of training was worth it. Thank you JVEC Solutions.",
      img: "/images/testimonial_page/omikunle.jpg",
    },
    {
      title: "Lawrence (Nigeria)",
      track: "WordPress Track",
      body: "JVEC Solutions' boot camp exceeded my expectations. The program's practical project-based learning and knowledgeable instructors served as a launchpad into tech for me. I highly recommend JVEC to anyone eager to start a career in web development.",
      img: "/images/testimonial_page/lawrence.jpg",
    },
    {
      title: "Jamilu (Nigeria)",
      track: "Motion Graphics Track",
      body: "My name is Jamilu Ya'u, and I had the incredible opportunity to attend a free motion graphics and video editing training course at JVEC SOLUTION COMPANY. This experience has truly been transformative for me. The training provided me with invaluable skills and a wealth of hands-on experience that I could not have gained elsewhere.\n\nThe instructors at JVEC SOLUTION COMPANY are exceptionally knowledgeable and patient, ensuring that every participant, regardless of their initial skill level, feels supported and empowered. The curriculum was comprehensive, covering everything from the basics of motion graphics to advanced video editing techniques. I was particularly impressed by the practical approach of the training - we were encouraged to work on real-world projects, which enhanced our learning process immensely.\n\nThe state-of-the-art facilities and the collaborative learning environment fostered by the company further enriched my experience. I found the staff to be friendly, approachable, and always willing to offer guidance and support. This made the learning process both enjoyable and efficient.\n\nThanks to JVEC SOLUTION COMPANY, I now possess a strong foundation in motion graphics and video editing, which has opened up new opportunities for me in my career. I wholeheartedly recommend JVEC SOLUTION COMPANY to anyone looking to enhance their skills in this field. Their commitment to quality education and their supportive community make them a standout choice for professional training.\n\nThank you, JVEC SOLUTION COMPANY, for equipping me with the tools to succeed and for a truly remarkable learning experience.",
      img: "/images/testimonial_page/Jamilu.jpg",
    },
    {
      title: "Umulkhayr (Nigeria)",
      track: "Data Analytics Track",
      body: "I learned how to use the essential arithmetic tools in Microsoft Excel and how to connect with mentors on LinkedIn. Thanks to JVEC Solutions for an impactful training.",
      img: "/images/testimonial_page/Umulkhayr.jpg",
    },
    {
      title: "Nonso (Nigeria)",
      track: "Project Management Track",
      body: "Hi, my name is Nonso and I recently finished a Project management boot camp at JVEC. During the program, I learned about project charter development, the influence of stakeholders on the project, project scope and its boundaries, etc. The class session was an interactive one with the facilitator ready and willing to answer and clarify any area of confusion. There was always an assignment at the end of every class. I will always recommend them to anyone ready to pursue a career in Tech.",
      img: "/images/testimonial_page/nonso.jpg",
    },
    {
      title: "Kingsley (Nigeria)",
      track: "Data Analytics Track",
      body: "Hi, I’m Kingsley, and I took this professional course in order to expand my knowledge and also saw the likelihood of venturing into it as a career path. The course exceeded my expectations as the instructor was very friendly and patient, carrying everyone along. Though it was a two-week program, it was worth it! Special thanks to the management for bringing up the two-week boot camp initiative.",
      img: "/images/testimonial_page/kingsley.jpg",
    },
    {
      title: "Ada (Nigeria)",
      track: "Data Analytics Track",
      body: "I started my journey in Data analytics with JVEC Solutions a few weeks ago and it’s been mind-blowing. I love how the trainers take their time to explain and answer questions. We gain maximum knowledge at a pocket-friendly fee. I’ve learned so many things I never knew how to do on Microsoft Excel and that’s exceptional for me!",
      img: "/images/testimonial_carousel/avatar5.jpeg",
    },
    {
      title: "Richard (Nigeria)",
      track: "WordPress/Digital Marketing Track",
      body: "My training with JVEC Solutions was an eye-opener for me. I got so much knowledge for a little fee. I was able to learn and eventually build a career in digital marketing and web development. Today, I am a certified Web developer and Digital Marketer. Many thanks to JVEC Solutions.",
      img: "/images/testimonial_page/Richard.jpeg",
    },
    {
      title: "Moses (Nigeria)",
      track: "WordPress Track",
      body: "Learning Website Development under the tutelage of JVEC Solutions was very rewarding. I recommend the organization to anyone willing to excel in this Tech-driven society; they are passionate about imparting knowledge.",
      img: "/images/testimonial_page/Moses Taire.jpeg",
    },
    {
      title: "Ufuoma (Nigeria)",
      track: "Data Analytics Track",
      body: "Learning Data Analytics from JVEC Solutions is one of my best decisions in life. Today, with my knowledge of Excel and Power BI, I have proved my proficiency as a Data Analyst in several job roles. I am grateful to JVEC Solutions for giving me a strong foundation as a Data Analyst.",
      img: "/images/testimonial_page/ufuomah img4.jpg",
    },
    {
      title: "Peace (Nigeria)",
      body: "Having the Data Analytics program (Power BI and SQL) with JVEC Solutions was truly impactful. The tutors are vastly experienced and very much successful in their chosen careers and they go an extra length in ensuring that everyone is carried along. I strongly recommend JVEC Solutions to anyone who wishes to be an expert in the Data Analytics field.",
      img: "/images/testimonial_carousel/avatar3.jpeg",
      track: "Data Analytics Track",
    },
    {
      title: "Thelma (Nigeria)",
      track: "Digital Marketing Track",
      body: "I took the JVEC' Digital Marketing training and it was all I needed to get started as a Digital Marketer. Packed with so much value, I went from barely any knowledge of digital marketing to placing highly-targeted ads on Facebook. I landed my first job as a Digital Marketer almost immediately. I can't thank JVEC Solutions enough.",
      img: "/images/testimonial_carousel/avatar4.jpg"
    },
    {
      title: "Michael (Nigeria)",
      track: "Data Science Track",
      body: "I began my journey as a Data Scientist with JVEC. My learning experience with JVEC Solutions during the cohort was nothing short of exceptional! The depth of knowledge and expertise shared by the trainers is remarkable. What sets JVEC apart is its dedication to providing practical insights and real-world skills that are immediately applicable. If you’re planning to go into Data Science, I recommend JVEC anytime, any day.",
      img: "/images/testimonial_page/Michael Edekin.jpg"
    },
    {
      title: "Kayode (Nigeria)",
      track: "Data Analytics Track",
      body: "Hi, I am Kayode – a product of JVEC Solutions. One of the things I love about the institute is the problem solving skills and ability to communicate information effectively. The instructors are accessible both online and offline with great teaching techniques. If you’re looking for where to learn, I will gladly recommend JVEC Solutions.",
      img: "/images/testimonial_page/kayode abidemi_copy.jpg"
    },
    {
      title: "Ruth (Nigeria)",
      track: "Data Analytics Track",
      body: "Before now, I have always seen Data Analytics as a big deal but with JVEC, it was made so easy. The facilitators are highly skilled at teaching, they made every class interesting and impactful. Now, analyzing Data with Microsoft Excel at work has become easy as a breeze.",
      img: "/images/testimonial_page/ruth aijoba.jpg"
    },
    {
      title: "Osita (Nigeria)",
      track: "Data Analytics Track",
      body: "After trying different Training institutes without success, I was delighted to learn that JVEC Solutions adopts a different approach. Not only do they have the depth of talent and experience in Microsoft Excel, Power BI, etc., but they also issue personal mentors who look after their students to reinforce what they have learned.",
      img: "/images/testimonial_page/Osita_copy.jpg"
    },
  ];

  let cardComponent = trainingCardInfo.map((element, index) => (
    <TrainingCard
      key={element.title}
      title={element.title}
      body={element.body}
      track={element.track} 
      img={element.img}
      url={element.url}
      className={
        index === trainingCardInfo.length - 1
          ? "md:col-span-full lg:col-span-1"
          : ""
      }
    />
  ));
  useEffect(() => {
    Aos.init({ duration: 900 });
    window.scrollTo(0, 0);
  }, []);

  return (
    <main className="min-h-screen major-background font-noto mb-20">
<TestimonialHero />
      <section className="px-[clamp(1em,8vw,5em)] mt-20">
        <div className="card-container">
          {cardComponent}
        </div>
      </section>
      <section id='contact-us-comp'>
        <ContactUs/>
        </section>
    </main>
  );
};

export default MainTestimonials;
