 const brands = [
    {
      name : "goldland logo",
      logo : "/images/brand_carousel/goldland.svg",
    },

    {
      name : "parotext logo",
      logo : "/images/brand_carousel/paro_foundation.svg",
    },

    {
      name : "ovision logo",
      logo : "/images/brand_carousel/Ovisione.svg",
    },

    {
      name : "scent redesign logo",
      logo : "/images/brand_carousel/scent_art_print.svg",
    },

    {
      name : "SD logo",
      logo : "/images/brand_carousel/SD-Onaiwi.svg",
    },
    
    {
      name : "uchenna Nnaji logo",
      logo : "/images/brand_carousel/Uche_nnaji_redesign.svg",
    },

    {
      name : "CAREERS VERIFIED logo",
      logo : "/images/brand_carousel/careers-verified.svg"
    },

    {
      name : "PENTAGENCY",
      logo : "/images/brand_carousel/pentagency.svg"
    },

    {
      name : "Stressless Movers",
      logo : "/images/brand_carousel/stressless-movers-dark.svg"
    },
    {
      name : "Oak",
      logo : "/images/brand_carousel/oak.svg"
    },
    {
      name : "Mamamoni",
      logo : "/images/brand_carousel/mamamoni.svg"
    },
    {
      name : "curacel",
      logo : "/images/brand_carousel/curacel.svg"
    },
    {
      name : "mes",
      logo : "/images/brand_carousel/mes.svg"
    },
  ];

export default brands;