const brands = [
  {
    name: "Oak Holdings",
    logo: "/images/brand_carousel/oak.svg",
    url: "https://www.instagram.com/theoakholdings/"
  },
  {
    name: "Curacel",
    logo: "/images/brand_carousel/curacel.svg",
    url: "https://www.curacel.co/"
  },
  {
    name: "Mamamoni",
    logo: "/images/brand_carousel/mamamoni.svg",
    url: "https://mamamoni.co/"
  },
  {
    name: "Stressless Movers",
    logo: "/images/brand_carousel/stressless-movers-dark.svg",
    url: "https://stresslessmover.com/"
  },
  {
    name: "MES",
    logo: "/images/brand_carousel/mes.svg",
    url: "https://www.mdeservicestnfinest.com/"
  },
  {
    name: "Careers Verified",
    logo: "/images/brand_carousel/careers-verified.svg",
    url: "https://careersverified.net/"
  },
  {
    name: "Pent Agency",
    logo: "/images/brand_carousel/pentagency.svg",
    url: "https://pentagency.com/"
  },
  {
    name: "Goldland Interiors",
    logo: "/images/brand_carousel/goldland.svg",
    url: "https://web.facebook.com/p/Goldland-Interiors-and-Furniture-100057155572627/"
  },
  {
    name: "SD Onaiwi",
    logo: "/images/brand_carousel/SD-Onaiwi.svg",
    url: "https://sdonaiwinigltd.com/"
  },
  {
    name: "Uchenna Nnaji",
    logo: "/images/brand_carousel/Uche_nnaji_redesign.svg",
    url: "https://uchennannaji.com/"
  },
  {
    name: "Ovisione",
    logo: "/images/brand_carousel/Ovisione.svg",
    url: "#"
  },
  {
    name: "Scent Art",
    logo: "/images/brand_carousel/scent_art_print.svg",
    url: "#"
  },
  {
    name: "Paro Foundation",
    logo: "/images/brand_carousel/paro_foundation.svg",
    url: "#"
  }
];

export default brands;