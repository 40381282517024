import React, { useEffect, useRef } from "react";
import "../../App.css";
import PortFolioCard from "./components/portfolioCard";
import PortfolioCta from "./components/portfolioCta";
import BrandCarousel from "../../components/brandCarousel/BrandCarousel";

const Portfolio = () => {
  const portfolioItems = [
    [
      { name: "OAK HOLDINGS", img: "./images/brand_carousel/oak.svg", url: "https://www.instagram.com/theoakholdings/" },
      { name: "CURACEL", img: "./images/brand_carousel/curacel.svg", url: "https://www.curacel.co/" }
    ],
    [
      { name: "SCENT ART", img: "./images/brand_carousel/scent_art_print.svg" },
      { name: "OVISIONE", img: "./images/brand_carousel/Ovisione.svg" }
    ],
    [
      { name: "GOLDLAND INTERIORS FURNITURE", img: "./images/brand_carousel/goldland.svg", url: "https://web.facebook.com/p/Goldland-Interiors-and-Furniture-100057155572627/" },
      { name: "UNC", img: "./images/brand_carousel/Uche_nnaji_redesign.svg", url: "https://uchennannaji.com/" }
    ],
    [
      { name: "S.D ONAIWI", img: "./images/brand_carousel/SD-Onaiwi.svg", url: "https://sdonaiwinigltd.com/" },
      { name: "PARO FOUNDATION", img: "./images/brand_carousel/paro_foundation.svg" }
    ],
    [
      { name: "CAREERS VERIFIED", img: "./images/brand_carousel/careers-verified.svg", url: "https://careersverified.net/" },
      { name: "PENTAGENCY", img: "./images/brand_carousel/pentagency.svg", url: "https://pentagency.com/" }
    ],
    [
      { name: "STRESSLESS MOVERS", img: "./images/brand_carousel/stressless-movers-dark.svg", url: "https://stresslessmover.com/" },
      { name: "MES", img: "./images/brand_carousel/mes.svg", url: "https://www.mdeservicestnfinest.com/" }
    ],
    [
      { name: "MAMAMONI", img: "./images/brand_carousel/mamamoni.svg", url: "https://mamamoni.co/", className: "w-full" },
    ],
  ];

  let ref = useRef();

  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => ref.current?.classList.add("showText"), 200);
  }, []);

  const renderPortfolioCard = ({ img, name, url, className="w-full md:w-1/2" }) => (
    <a 
      key={name}
      href={url}
      target="_blank"
      rel="noopener noreferrer"
      className={className}
    >
      <PortFolioCard 
        imgSrc={img} 
        cardCaption={name}
      />
    </a>
  );

  return (
    <main className="min-h-screen px-[clamp(1em,8vw,5em)] w-full min-w-screen h-fit text-white font-sans major-background flex flex-col justify-between">
      <div className="header-text md:max-w-[75%] lg:max-w-[65%] text-[clamp(3.2em,8vw,6em)] lg:text-[clamp(2.5em,8vw,7em)] text-white font-bold font-noto leading-none">
        <h1 className="w-full pt-40 text-left mt-14">Where Clients meet</h1>
        <div className="overflow-hidden">
          <h1 ref={ref} className="hideText">Satisfaction</h1>
        </div>
      </div>
      <section className="w-full h-fit grid gap-y-3 mt-12 md:mt-24 mb-20">
        {portfolioItems.map((row, index) => (
          <div key={index} className="flex-col md:flex-row flex gap-3">
            {row.map(renderPortfolioCard)}
          </div>
        ))}
      </section>
      <BrandCarousel />
      <div className="mt-8"></div>
      <PortfolioCta />
    </main>
  );
};

export default Portfolio;